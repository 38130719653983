import React, { useEffect, useState } from 'react';
import { Row, Spin, Button, Col } from 'antd';
import ImageCard from '../../../components/ImageWithLabel'
import { useNavigate, useParams } from "react-router-dom";
import { materialList } from '../../../services/materialsApi';
import Breadcrumbs from '../../../components/CategoryBreadcrumbs';

interface MaterialsProps {
  isLearning: number
}

const Materials: React.FC<MaterialsProps> = ({isLearning = 0}) => {
  const [materials, setMaterials] =  useState<any>([]);
  const [isLoading, setIsLoading] =  useState<boolean>(false);

  let navigate = useNavigate();
  let { id = 0 } = useParams();
  useEffect(() => {
    setIsLoading(true);
    materialList(+id, isLearning).then(result => {
      setMaterials(result);
      setIsLoading(false);
    });
  }, [id]);
  const handleClick = (id: number) => {
    navigate('/learning/material/'+id)
  }
  console.log(materials);
  return (
    <Spin spinning={isLoading}>
      {!isLearning && <Breadcrumbs id={id} />}
      <Row gutter={[16, 16]}>
        {!!isLearning && !materials.length && <Col style={{fontSize: 20, lineHeight: '40px', marginTop: 20}}>
          Здесь отображаются материалы на изучении. <br />
          Начните изчучение в разделе "Материалы" что бы изучаемые материалы отобразились здесь.
        </Col>}
        {materials.map((item: any) => (
          <ImageCard src={'/api/public/materials/img/'+item.imgSource} label={item.name} key={item.id} onClick={null}>
            <div style={{marginTop:4}}>Баллов за прохождение теста: <span style={{color: '#A40207'}}>до +{item.prize?.split(';')[0]}</span></div>
            <div style={{marginTop:4}}>Изучение займет: {item.timeForLearn} минут</div>
            <Button onClick={()=>handleClick(item.id)} type="primary" style={{ marginTop: '8px' }}>
              Пройти обучение
            </Button>
          </ImageCard>
        ))}
      </Row>
    </Spin>
);
};

export default Materials;

