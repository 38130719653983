import { get, post } from './fetch';

export interface Test {
  name: string;
  prize: number;
  testTime: number;
}
export interface Questions {
  questions: Array<any>;
}
export const sendResult = async (resultId: number, answers: any): Promise<Test[]> => {
    return await post<Test[]>('/test/result', {resultId, answers});
};
export const testStart = async (id: number): Promise<any> => {
    return await get<any>('/test/start?materialId='+id);
};
export const oneTest = async (id: number): Promise<Test> => {
    return await get<Test>('/test/one?id='+id);
};
export const testStat = async () => {
    return await get<any>(`/test/stat`);
};
export const editPrizes = async (materialId: number, managerId: string, prizes: string): Promise<boolean> => {
    return await post<boolean>('/test/editPrizes', {materialId, managerId, prizes});
};

