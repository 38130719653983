import React, { createContext, useContext, useState, useEffect } from 'react';
import { loginRequest, userDataRequest } from '../services/userApi';

interface AuthContextType {
  user: any;
  isAdmin: any;
  isAuthenticated: boolean;
  logout: () => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export const removeTokens = (): void => {
  localStorage.setItem('isAdmin', '0');
  localStorage.setItem('isAdminMode', '0');
  localStorage.removeItem('user');
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('user'));
  const [isAdmin, setIsAdmin] = useState<any>(localStorage.getItem('isAdmin'));
  const [user, setUser] = useState<any>(JSON.parse(localStorage.getItem('user') || '{}'));

  useEffect(()=>{
    if(window.location.pathname==='/login') return;
    userDataRequest().then((user)=>{
      if(user?.id) {
        localStorage.setItem('isAdmin', user?.isAdmin);
        localStorage.setItem('user', JSON.stringify(user));
        setIsAdmin(user?.isAdmin || user.role>0);
        setUser(user);
        setIsAuthenticated(true);
      } else {
        logout()
      }
    }).catch(()=>{
      logout()
    })
  }, [])

  const logout = () => {
    document.cookie = 'token=; Max-Age=-99999999;';
    removeTokens();
    setIsAuthenticated(false);
    setIsAdmin(0);
    setUser({});
    window.location.replace(!user.role ? 'https://liderprodag.ru/exit.html' : 'https://liderprodag.ru/admin?module=exit');
    //window.location.href ='/login';
  };

  return (
    <AuthContext.Provider value={{ user, isAdmin, isAuthenticated, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};