import { Link, useLocation, } from 'react-router-dom';
import { Menu } from 'antd';

interface MenuItemProps {
  to: string;
  label: string | React.ReactNode;
}

const MenuItem: React.FC<MenuItemProps> = ({ to, label }) => {
  const { pathname } = useLocation();
  return (
    <Menu.Item key={to} className={`menu-item ${pathname === to ? 'menu-link-active' : ''}`}>
      <Link to={to} className="menu-link">
        {label}
      </Link>
    </Menu.Item>
  );
};

export default MenuItem