import React, { useState } from 'react';
import { Form, Input, Button,} from 'antd';
const Login: React.FC = () => {
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(1);

  const onFinish = async () => {
    document.cookie = "token="+token;
    window.location.replace('/')
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }}>
      {!isSuccess ?
        <div>
          <h2 style={{ textAlign: 'center', paddingBottom: 20 }}>Доступ к ресурсу ограничен. <br /> Для получения доступа обратитесь к своему МПП.</h2>
        </div>
        :
        <div style={{ maxWidth: '400px', width: '100%' }}>
          <h2 style={{ textAlign: 'center', paddingBottom: 20 }}>Вход</h2>
          <Form onFinish={onFinish} layout="vertical" className="login-form">
            <Form.Item label="Token" name="Token">
              <Input placeholder="Token" onChange={(e)=>setToken(e.target.value)}/>
            </Form.Item>
            <Form.Item>
              <Button loading={isLoading} type="primary" htmlType="submit" style={{ width: '100%' }}>
                Войти
              </Button>
            </Form.Item>
          </Form>
        </div>}
    </div>
  );
};

export default Login;