import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { categoryBreadcrumbs } from '../services/categoryApi';

interface BreadcrumbProps {
  id: number | string;
  lastItem?: string;
}

const Breadcrumbs: React.FC<BreadcrumbProps> = ({ id, lastItem }) => {
  
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  useEffect(() => {
    categoryBreadcrumbs(+id).then(result => {
      setBreadcrumbs([
        { href: '/learning/categories', title: 'Все материалы' },
        ...result.reverse().map((x, i) => (i + 1 === result.length ? { title: x.name, ...(lastItem && { href: '/learning/materials/' + x.id }) } : { href: '/learning/categories/' + x.id, title: x.name })),
        ...(lastItem? [{ title: lastItem }] : [])
      ]);
    });
  }, [id]);

  return (
    <Breadcrumb
      style={{ marginTop: 10 }}
      separator=">"
      items={breadcrumbs}
    />
  );
};

export default Breadcrumbs;