import React, { useState, useEffect } from 'react';
import { Modal, Input, Col, Row, Button, DatePicker, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { changeStatusSpecial, oneSpecial, saveSpecial } from '../../../../services/specialApi';
import { getStateUsersByClients } from '../../../../services/adminApi';
import { getMaterialListByCategories } from '../../../../services/materialsApi';
import SpecialsUsersModal from './SpecialUsersModal';
import SpecialsMaterialsModal from './SpecialMaterialsModal';
import dayjs from 'dayjs';
import { useAuth } from '../../../../hooks/auth';

interface ArrayWithChildrens {
  id: number | null;
  name: string;
  children: ArrayWithChildrens[];
}
interface RuleModalProps {
  specialData: any;
  onSave: (ruleData: any) => void;
  state: {id: number, name: string}
}
interface TreeNode {
  title: string;
  key: string;
  children?: TreeNode[];
}
const transformDataToTree = (data: ArrayWithChildrens[], type?: string): any[] => {
  return data.map((el) => ({
    ...el,
    title: el.name ?? (type === 'user' ? 'Без клиента' : 'Без категории'),
    key: el.id !== null ? `group-${el.id}` : 'no-id',
    children: el.children.map((children) => ({
      ...children,
      parent: el.id,
      title: children.name,
      key: `element-${children.id}`,
    })),
  }));
};

const SpecialsModal: React.FC<RuleModalProps> = ({ specialData, onSave, state }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [period, setPeriod] = useState<any>({});
  const [materialPrizes, setMaterialPrizes] = useState<any>({})
  const [users, setUsers] = useState<number[] | undefined>([])
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenUserSelect, setIsOpenUserSelect] = useState(false);
  const [isOpenMaterialSelect, setIsOpenMaterialSelect] = useState(false);
  const [userTree, setUserTree] = useState<TreeNode[]>([]);
  const [materials, setMaterials] = useState<any[]>([]);
  
  const { user } = useAuth();

  const currentSpecial: any = { title, description, period, materials: materialPrizes, users, id: specialData?.id };
  if(state) currentSpecial.stateId = state.id;

  const isFormFullfil = title.length && description.length && period?.start && period?.end && users?.length && materials.length;

  useEffect(() => {
    if (specialData?.id) {
      setTitle(specialData.title);
      setPeriod({ start: dayjs(specialData.start), end: dayjs(specialData.end) })
      setDescription(specialData.description)
      oneSpecial(specialData.id).then(result => {
        setMaterialPrizes(result.materials)
        setUsers(result.users)
      })
    } else {
      setTitle('')
      setPeriod({})
      setDescription('')
      setMaterialPrizes({})
      setUsers([])
    }
    getMaterialListByCategories(specialData?.stateId).then(result => {
      setMaterials(transformDataToTree(result));
    })
  }, [specialData])

  useEffect(() => {
    //if (specialData?.id) setUsers([]);
    if (!!(materialCount && period.start && period.end)) {
      getStateUsersByClients(specialData?.stateId || currentSpecial?.stateId, !currentSpecial?.id ? period : false, Object.keys(materialPrizes)).then(result => {
        setUserTree(transformDataToTree(result, 'user'));
      })
    }
  }, [period, materialPrizes])

  const save = async () => {
    const special = { ...currentSpecial };
    const savedRule = await saveSpecial(special);
    if (!savedRule) {
      notification.error({ message: 'Произошла ошибка при сохранении' })
      return;
    }
    onSave(savedRule);
  };

  const changeStatus = async (status: number = 0) => {
    const savedRule = await changeStatusSpecial(currentSpecial.id, status);
    if (!savedRule) {
      notification.error({ message: 'Произошла ошибка при сохранении' })
      return;
    }
    onSave({ ...specialData, status });
  };

  const onUsersSave = (values: number[]) => {
    setUsers(values);
  };

  const onMaterialsSave = (values: number[]) => {
    setMaterialPrizes(values);
  };
  const isEditable = !currentSpecial?.id;
  const materialCount = Object.keys(materialPrizes).length;
  return (
    <>
      <Modal
        width={900}
        title={specialData?.id ? 'Изменить текущую акцию' : 'Создание акции'}
        open={specialData}
        onCancel={() => onSave(false)}
        footer={[
          !specialData?.id && (
            <Button key="create" loading={isLoading} onClick={() => save()} disabled={!isFormFullfil}>
              Создать
            </Button>
          ),
          ((specialData?.id && user.isAdmin) || (user.role === 3 && specialData?.status === 0)) && (
            <>
              <Button key="reject" type="primary" loading={isLoading} onClick={() => changeStatus(2)} disabled={!isFormFullfil}>
                Отклонить
              </Button>
              <Button key="approve" type="primary" loading={isLoading} onClick={() => changeStatus(1)} disabled={!isFormFullfil}>
                Одобрить
              </Button>
            </>
          ),
        ]}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            Название:
            {isEditable ? <Input value={title} onChange={(e) => setTitle(e.target.value)} /> : <><br />{title}</>}
          </Col>
          <Col span={12}>
            Дата начала: 
            {isEditable ? <DatePicker minDate={dayjs().add(1, 'day')} value={period?.start ? period?.start : null} format="DD.MM.YYYY" style={{ width: '100%' }} locale={locale} onChange={(date) => setPeriod({ ...period, 'start': date })} /> : <><br />{dayjs(period?.start).format('DD.MM.YYYY')}</>}
          </Col>
          <Col span={12}>
            Дата окончания:
            {isEditable ? <DatePicker minDate={dayjs().add(1, 'day')} value={period?.end ? period?.end : null} format="DD.MM.YYYY" style={{ width: '100%' }} locale={locale} onChange={(date) => setPeriod({ ...period, 'end': date })} /> : <><br />{dayjs(period?.end).format('DD.MM.YYYY')}</>}
          </Col>
          <Col span={24}>
            Материалы: {materialCount}
            <Link style={{ marginLeft: 7, fontSize: 18 }} to='#' onClick={() => setIsOpenMaterialSelect(true)}><EditOutlined /></Link>
          </Col>
          {!!(materialCount && period.start && period.end) && <Col span={24}>
            Пользователи: {users?.length || 0}
            <Link style={{ marginLeft: 7, fontSize: 18 }} to='#' onClick={() => setIsOpenUserSelect(true)}><EditOutlined /></Link>
          </Col>}
          <Col span={24}>
            Описание:
            {isEditable ? <TextArea value={description} style={{ height: 100 }} onChange={(e) => setDescription(e.target.value)}></TextArea> : <><br />{description}</>}
          </Col>
        </Row>
      </Modal>
      <SpecialsUsersModal
        isEditable={isEditable}
        userTree={userTree}
        users={users}
        onSave={onUsersSave}
        isOpen={isOpenUserSelect}
        setIsOpen={setIsOpenUserSelect}
      />
      <SpecialsMaterialsModal
        isEditable={isEditable}
        materialPrizes={materialPrizes}
        materials={materials}
        onSave={onMaterialsSave}
        isOpen={isOpenMaterialSelect}
        setIsOpen={setIsOpenMaterialSelect}
      />
    </>
  );
};

export default SpecialsModal;