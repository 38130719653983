import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Select, Spin, DatePicker, Button } from 'antd';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { getAllStates, getReport } from '../../services/adminApi';
import { Checkbox } from 'antd';
import type { GetProp } from 'antd';

<DatePicker locale={locale} />;

const { RangePicker } = DatePicker;

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

const Users: React.FC = () => {
  const [states, setStates] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const state = useRef<number>(0);
  const period = useRef<Array<string>>([])
  const content = useRef<Array<string | number | boolean>>([])

  useEffect(() => {
    setIsLoading(true);
    getAllStates().then(result => {
      setIsLoading(false);
      setStates(result.sort((a, b) => a.name.localeCompare(b.name)));
    });
  }, [])

  const periodChange = (value: any, dateString: any) => {
    period.current = dateString;
  };

  const onCheck: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    content.current = checkedValues;
  };

  const downloadReport = async () => {
    const result = await getReport(state.current, period.current, content.current)
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    const blob = new Blob([result], { type: "octet/stream" });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = period.current.join(' - ') +'.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const options: Option[] = [
    // { label: 'Кол-во баллов', value: 'prize' },
    // { label: 'Дата регистрации', value: 'register' },
    // { label: 'Количество', value: 'amount' },
    { label: 'Добавить список пройденных тестов за период', value: 'tests' },
  ];

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 16]} style={{ marginTop: 40 }} >
        <Col span={24} style={{ fontSize: 25 }}>
          Отчеты
        </Col>
        <Col sm={8} xs={24}>
          Филиал:
          <Select
            style={{ marginTop: 7, width: '100%' }}
            defaultValue={0}
            options={[{ label: 'Выберите филиал', value: 0 }, ...states.map(x => ({ value: x.id, label: x.name }))]}
            onChange={(val) => state.current = val}
          />
        </Col>
        <Col sm={16} xs={24}>
          Период:
          <RangePicker
            locale={locale}
            style={{ marginTop: 7, width: '100%' }}
            onChange={periodChange}
          />
        </Col>
        <Col span={24}>
          Наполнение отчета: <br />
          <Checkbox.Group style={{ marginTop: 7, width: '100%' }} options={options} onChange={onCheck} />
        </Col>
        <Button onClick={downloadReport}>Скачать</Button>
      </Row>
    </Spin>
  );
};

export default Users;
