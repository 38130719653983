import { get, post } from './fetch';

export interface Material {
  id: number;
  name: string;
  timeForLearn: number;
  imgSource: string;
  fileSource: string;
  html: string;
  categoryId: number;
  prizes: string;
  questionsAmount: number;
  testTime: number;
}

export const materialList = async (category: number, isLearning: number): Promise<Material[]> => {
  return await get<Material[]>(isLearning ? '/material/listLearning' : '/material/list?category=' + category);
};
export const materialListForAdmin = async (category: number): Promise<Material[]> => {
  return await get<Material[]>('/material/listForAdmin?category=' + category);
};
export const newMaterial = async (material: any): Promise<Material[]> => {
  try {
    return await post<Material[]>('/material/add', material);
  } catch (error) {
    throw new Error('Error occurred while fetching material');
  }
};
export const oneMaterial = async (id: number, isLearn: number): Promise<any> => {
  try {
    return await get<Material>(`/material/one?id=${id}&isLearn=${isLearn}`);
  } catch (error) {
    throw new Error('Error occurred while fetching material');
  }
};
export const deleteMaterial = async (id: number) => {
  try {
    return await get<Material>('/material/delete?id=' + id);
  } catch (error) {
    throw new Error('Error occurred while fetching material');
  }
};
export const publishMaterial = async (id: number, status: boolean) => {
  try {
    return await get<Material>(`/material/publish?id=${id}&status=${status}`);
  } catch (error) {
    throw new Error('Error occurred while fetching material');
  }
};
export const saveMaterialAccess = (materialId: number, stateIds: number[]): Promise<boolean> => {
  return post(`/material/saveMaterialAccess`, { materialId, stateIds });
};
export const getMaterialListByCategories = async (stateId?: number): Promise<any> => { //[{ title: string, id: number, children: Material[] }]
  return await get('/material/listByCategories?stateId='+stateId);
};

