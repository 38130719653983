import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Select, Table, Spin, Checkbox, notification } from 'antd';
import { userList, changeAccessUserPart } from '../../services/userApi';
import { getAllStates } from '../../services/adminApi';

const Users: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>({ 0: { id: 0, name: 'Пользователь' } });
  const [search, setSearch] = useState('');
  const [state, setState] = useState<number>(0);
  const [role, setRole] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    userList().then(result => {
      setIsLoading(false);
      setUsers(result.users.map((x: any) => ({ ...x, key: x.id })));
      setRoles({...roles, ...result.roles});
    });
    getAllStates().then(result => {
      setIsLoading(false);
      setStates(result.sort((a, b) => a.name.localeCompare(b.name)));
    });
  }, [])
  const filterUsers = (users: any) => {
    let result = users;
    if (search.length >= 3) result = result.filter((user: any) => user.name?.toLowerCase()?.indexOf(search) >= 0 || user.email?.toLowerCase()?.indexOf(search) >= 0 || user.fio?.toLowerCase()?.indexOf(search) >= 0 || user.phone?.toLowerCase()?.indexOf(search) >= 0);
    if (state) result = result.filter((user: any) => user.stateId === state);
    if (role>=0) result = result.filter((user: any) => user.role === role);
    return result;
  }
  const setAccessUserPart = async (userId: number, status: boolean) => {
    if(await changeAccessUserPart(userId, status)) {
      const newUsers = users.map((user:any)=>{
        if(user.id === userId) return {...user, accessUserPart: status};
        return user;
      })
      setUsers(newUsers);
    } else notification.error({ message: 'Произошла ошибка при сохранении' })
  }
  const columns = [
    {
      title: 'Имя пользователя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Баланс',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'ФИО',
      dataIndex: 'fio',
      key: 'fio'
    },
    {
      title: 'Филиал',
      dataIndex: 'stateName',
      key: 'stateName',
      sorter: (a: any, b: any) => (a.stateName ?? '').localeCompare(b.stateName ?? ''),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      render: (val: number, row: any) => row.isAdmin ? 'Админ' : roles[val].name,
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Пользовательский доступ',
      dataIndex: 'accessUserPart',
      key: 'accessUserPart',
      render: (val: boolean, row: any) =>  [2,3,4,5].includes(row.role) ? <Checkbox checked={!row.role || val} onChange={(e)=>setAccessUserPart(row.id, e.target.checked)}/> : row.access===0?'Заблокирован':'Активен'
    },
  ];
  const showingUsers = filterUsers(users);
  console.log(showingUsers);
  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 16]} style={{ marginTop: 40 }} >
        <Col span={24} style={{ fontSize: 25 }}>
          Список пользователей
        </Col>
        <Col sm={12} xs={24}>Поиск:<Input style={{ marginTop: 7 }} placeholder='Имя пользователя / E-mail / ФИО / Телефон' onChange={(e) => { setSearch(e.target.value.toLowerCase()) }} /></Col>
        <Col sm={6} xs={24}>Филиал:<Select style={{ marginTop: 7, width: '100%' }} defaultValue={0} options={[{ label: 'Выберите филиал', value: 0 }, ...states.map(x => ({ value: x.id, label: x.name }))]} onChange={setState} /></Col>
        <Col sm={6} xs={24}>Роль:<Select style={{ marginTop: 7, width: '100%' }} defaultValue={-1} options={[{ label: 'Выберите роль', value: -1 }, ...Object.values(roles).map((x:any) => ({ value: x.id, label: x.name }))]} onChange={setRole} /></Col>
        <Col span={24}>
          <div style={{ fontSize: 14, marginBottom: 10 }}>Всего пользователей: {showingUsers.length}</div>
          <Table
            scroll={{ x: 300 }}
            columns={columns}
            dataSource={showingUsers}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default Users;
