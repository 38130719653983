import React, { useState, useEffect } from 'react';
import { Modal, Button, Tree } from 'antd';
import type { TreeProps } from 'antd';

interface RuleUsersModalProps {
  isEditable: boolean;
  users: any[] | undefined;
  onSave: (users: any[]) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userTree: any[]
}

const SpecialsUsersModal: React.FC<RuleUsersModalProps> = ({ isEditable, isOpen, setIsOpen, users, onSave, userTree }) => {
  const [selectedUsers, setSelectedUsers] = useState<any[] | undefined>(users)
  useEffect(()=>{
    setSelectedUsers(users);
  },[users])

  const save = async () => {
    onSave(selectedUsers ?? []);
    setIsOpen(false);
  };

  const onUserCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    const checkedUserIds = (checkedKeys as string[]).filter(key => key.startsWith('element-'));
    setSelectedUsers(checkedUserIds);
  };

  return (
    <Modal
      width={700}
      title='Выбор участников'
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={isEditable ? [
        <Button key="submit" type="primary" onClick={save}>
          Сохранить
        </Button>,
      ] : []}
    >
      <div style={{ marginBottom: 15 }}>

        <span style={{ float: 'right' }}>Участиники: {selectedUsers?.length}</span>
      </div>
      <Tree
        checkable
        checkedKeys={selectedUsers}
        defaultExpandedKeys={["all"]}
        treeData={[
          {
            key: "all",
            title: "Все пользователи",
            children: userTree,
          },
        ]}
        onCheck={onUserCheck}
      />
    </Modal>
  );
};

export default SpecialsUsersModal;