import { useCallback, useRef } from 'react';

export const useDebounce = (callback:any, delay = 500) => {
  const timer = useRef<any>(null);

  return useCallback((...args:any) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);
};