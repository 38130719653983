import dayjs from 'dayjs';
import { get, post } from './fetch';

export interface Special {
  id?: number;
  title: string;
  description: string;
  period: {start: dayjs.Dayjs, end: dayjs.Dayjs}
  users?: number[];
  materials?: any;
  status?: number;
}

export const saveSpecial = async (special: Special): Promise<Special> => {
  const period = { start: special.period.start.format('YYYY-MM-DD'), end: special.period.end.format('YYYY-MM-DD') }
  return await post('/special/save', { ...special, period: period });
};

export const listSpecial = async (page: number | undefined = 1, search: string, state: number ):  Promise<{rows: Array<Special>, count: number}> => {
  const params = new URLSearchParams({
    page: (page - 1).toString(),
    state: state.toString(),
    name: search || '',
  });
  return await get(`/special/list?${params.toString()}`);
};

export const oneSpecial = async (id: number):  Promise<Special> => {
  return await get('/special/one?id='+id);
};

export const changeStatusSpecial = async (id: number, status: number):  Promise<Special> => {
  return await get('/special/status?id='+id+'&status='+status);
}; 

export const deleteSpecial = async (id: number):  Promise<boolean> => {
  return await get('/special/delete?id='+id);
}; 