import {post} from "./fetch";

interface LoginResponse {
    accessToken: string;
}

interface SignUpPayload {
    lastName: string;
    firstName: string;
    middleName: string;
    birthDate: string;
    email: string;
    phone?: string;
    password: string;
    stateId: number;
    stateName: string;
    storeName?: string;
    storeAddress?: string;
}

const setToken = (token: string): void => {
    localStorage.setItem('accessToken', token);
};

export const loginRequest = async (credentials: { email: string; password: string }): Promise<LoginResponse> => {
    try {
        const response: LoginResponse = await post('/auth/login', {
            email: credentials.email,
            password: credentials.password,
        });

        if (response?.accessToken) {
            setToken(response.accessToken);
        }

        return response;
    } catch (error: any) {
        if (error?.message) {
            throw new Error(error?.message || 'Ошибка авторизации');
        } else {
            throw new Error('Ошибка авторизации');
        }
    }
};

export const signUpRequest = async (payload: SignUpPayload): Promise<any> => {
    try {
        return await post('/auth/signup', payload);
    } catch (error: any) {
        if (error?.message) {
            throw new Error(error?.message || 'Ошибка регистрации');
        } else {
            throw new Error('Ошибка регистрации');
        }
    }
};

export const requestPasswordReset = async (email: string): Promise<void> => {
    try {
        await post('/auth/request-password-reset', { email });
    } catch (error) {
        throw new Error('Failed to request password reset');
    }
};

export const resetPassword = async (resetToken: string, newPassword: string): Promise<void> => {
    try {
        await post('/auth/reset-password', { resetToken, newPassword });
    } catch (error) {
        throw new Error('Failed to reset password');
    }
};