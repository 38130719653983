import React, { useEffect, useState } from 'react';
import { Row, Col, Select, DatePicker, Divider, Modal, Table, Button, Spin } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import Chart from './Chart';
import { dashboardData, userPrizes } from '../../../services/adminApi';
import num from '../../../utils/numberFormat';
import declinationOfNumerals from '../../../utils/declinationOfNumerals';

<DatePicker locale={locale} />;

const { RangePicker } = DatePicker;

interface HighlightedChangeProps {
  val: string;
}

const HighlightedChange: React.FC<HighlightedChangeProps> = ({ val }) => {
  return <span className='daily green'>{val === '0' ? '' : '+'}{val} <ArrowUpOutlined /></span>
};

interface DataWithBarProps {
  name: string;
  val: number;
  percent: number;
}

const DataWithBar: React.FC<DataWithBarProps> = ({ name, val, percent }) => {
  return (
    <div style={{ fontWeight: 600, marginTop: 25 }}>
      {name}<div style={{ float: 'right' }}>{val.toLocaleString()}</div>
      <div style={{ height: 5, borderRadius: 5, background: '#E7E7E7', marginTop: 10 }}><div style={{ height: 5, borderRadius: 5, background: '#A40207', width: percent * 100 + '%' }}></div></div>
    </div>
  )
};

const Dashboard: React.FC = () => {
  const [period, setPeriod] = useState<Array<string>>([])
  const [data, setData] = useState<any>({ prizeByState: [], attendanceByState: [], prizeByUser: [], allTestsPopularity: [] })
  const [stateAttendance, setStateAttendance] = useState<Array<any>>([])
  const [statePrize, setStatePrize] = useState<Array<any>>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState<string>('');
  const [prizesByUserId, setPrizesByUserId] = useState<any>({});
  const [prizesExpanded, setPrizesExpanded] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const periodChange = (value: any, dateString: any) => {
    setPeriod(dateString);
  };

  useEffect(() => {
    setIsLoading(true);
    dashboardData(period).then(data => {
      setData(data);
      setStateAttendance(data.attendanceByState);
      setStatePrize(data.prizeByState);
      setIsLoading(false);
    })
  }, [period])
  const columns: any = {
    stateAttendance: [
      {
        title: 'Филиал',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: 'Посетителей',
        dataIndex: 'count',
        key: 'count',
      },
    ],
    statePrize: [
      {
        title: 'Филиал',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: 'Баллов',
        dataIndex: 'prize',
        key: 'prize',
      },
    ],
    userPrize: [
      {
        title: 'ФИО',
        dataIndex: 'fio',
        key: 'fio',
      },
      {
        title: 'Филиал',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: 'Баллов',
        dataIndex: 'prize',
        key: 'prize',
        //render: (prize: number, row: any) => <a onClick={() => showUserPrizeModal(row.id)}>{prize}</a>
      },
    ],
    test: [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Кол-во прохождений',
        dataIndex: 'popularity',
        key: 'popularity',
        sorter: (a: any, b: any) => a.popularity - b.popularity,
      },
    ],
  };
  const dataSource: any = {
    stateAttendance: data.attendanceByState,
    statePrize: data.prizeByState,
    userPrize: data.prizeByUser,
    test: data.allTestsPopularity,
  };
  const modalTitle: any = {
    stateAttendance: 'Посещаемость по филиалам',
    statePrize: 'Баллы по филиалам',
    userPrize: 'Топ пользователей',
    test: 'ТОП тестов',
  };
  const showAll = (type: any) => {
    setModalType(type);
    setIsModalOpen(true);
  }

  const getUserPrizeData = async (isExpand: boolean, row: any) => {
    if (!isExpand) setPrizesExpanded([]);
    else {
      if (!prizesByUserId[row.user.id]) {
        const data = (await userPrizes(row.user.id)).success;
        const modified = { [row.user.id]: data, ...prizesByUserId };
        setPrizesByUserId(modified);
      }
      setPrizesExpanded([row.user.id])
    }
  }

  const showUserPrizeModal = (row: any) => {
    return <Table
      dataSource={prizesByUserId[row.user.id] || []}
      columns={[
        {
          title: 'Название',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Дата',
          dataIndex: 'date',
          key: 'date',
        },
        {
          title: 'Баллов',
          dataIndex: 'prize',
          key: 'prize',
        },
      ]}
    />;
  }

  return (
    <Spin spinning={isLoading}>
      <div className='dashboard'>
        <Modal
          title={modalTitle[modalType]}
          open={isModalOpen}
          onCancel={() => { setIsModalOpen(false) }}
          footer={[<Button onClick={() => setIsModalOpen(false)}> Закрыть </Button>]}
        >
          <Table
            dataSource={dataSource[modalType]}
            columns={columns[modalType]}
            expandable={modalType === 'userPrize' ? {
              expandedRowKeys: prizesExpanded,
              onExpand: getUserPrizeData,
              expandedRowRender: showUserPrizeModal,
              rowExpandable: (record) => record.prize > 0,
            } : {}}
          />
        </Modal>
        <Row gutter={[16, 16]} style={{ marginTop: 40 }} >
          <Col span={16} style={{ fontSize: 26, fontWeight: 600 }}>
            Статистика
          </Col>
          <Col span={8}>
            <RangePicker locale={locale} style={{ width: '100%' }} onChange={periodChange} />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 30 }} >
          <Col xl={6} sm={12} xs={12}>
            <div className='card'>
              <div className='header'>ПОЛЬЗОВАТЕЛИ</div>
              <div className='content'>
                <span className='sum'>{num(data?.allUsersCount)}</span>
                <HighlightedChange val={num(data?.periodUsersCount)} />
              </div>
            </div>
          </Col>
          <Col xl={6} sm={12} xs={12}>
            <div className='card'>
              <div className='header'>ПОСЕЩАЕМОСТЬ</div>
              <div className='content'>
                <span className='sum'>{num(data?.allAttendanceCount)}</span>
                <HighlightedChange val={num(data?.periodAttendanceCount)} />
              </div>
            </div>
          </Col>
          <Col xl={6} sm={12} xs={12}>
            <div className='card'>
              <div className='header'>ПРОЙДЕНО ТЕСТОВ</div>
              <div className='content'>
                <span className='sum'>{num(data?.allTestCount)}</span>
                <HighlightedChange val={num(data?.periodTestCount)} />
              </div>
            </div>
          </Col>
          <Col xl={6} sm={12} xs={12}>
            <div className='card'>
              <div className='header'>ВСЕГО БАЛЛОВ</div>
              <div className='content'>
                <span className='sum'>{num(data?.sumPrize)}</span>
                <HighlightedChange val={num(data?.periodSumPrize || 0)} />
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col xl={18} sm={24} xs={24}>
            <div className='card' style={{ height: '100%' }}>
              <Row>
                <Col span={14} style={{ fontWeight: 600 }}>Посещаемость</Col>
              </Row>
              <div style={{ height: 'calc(100% - 40px)', marginTop: 20 }}>{data && <Chart data={data.attendanceChart} />}</div>
            </div>
          </Col>
          <Col xl={6} sm={24} xs={24}>
            <div className='card' style={{ minHeight: 420 }}>
              <Row>
                <Col span={14} style={{ fontWeight: 600 }}>Посещаемость по филиалам</Col>
                <Col span={10}><Select onChange={() => setStateAttendance([...stateAttendance].reverse())} style={{ width: '100%' }} defaultValue={'desc'} options={[{ value: 'desc', label: 'По убыванию' }, { value: 'asc', label: 'По возрастанию' }]} /></Col>
              </Row>
              {stateAttendance.slice(0, 5).map((x: any) => <DataWithBar name={x.state} val={x.count} percent={x?.count / data?.attendanceByState[0]?.count} />)}
              <div onClick={() => showAll('stateAttendance')} style={{ position: 'absolute', bottom: 15, right: 30, fontSize: 14, color: '#A40207', cursor: 'pointer' }}>Посмотреть все &gt;</div>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }} >
          <Col xl={18} sm={24} xs={24}>
            <div className='card' style={{ height: '100%' }}>
              <Row>
                <Col span={24} >
                  <div style={{ fontWeight: 600 }}>Топ пользователей</div>
                  <div className='header'>по количеству баллов за период</div>
                </Col>
                <Divider />
                {data.prizeByUser.slice(0, 4).map((x: any) =>
                  <>
                    <Col span={10} style={{ fontWeight: 600 }}>{x.fio}</Col>
                    <Col span={10}>{x.state}</Col>
                    <Col span={4} style={{ textAlign: 'right' }}><span style={{ color: '#14532D', fontWeight: 600, background: '#DCFCE7', padding: 7, borderRadius: 15 }}>{num(x.prize)}</span></Col>
                    <Divider />
                  </>
                )}
              </Row>
              <div onClick={() => showAll('userPrize')} style={{ position: 'absolute', bottom: 15, right: 30, fontSize: 14, color: '#A40207', cursor: 'pointer' }}>Посмотреть все &gt;</div>
            </div>
          </Col>
          <Col xl={6} sm={24} xs={24}>
            <div className='card' style={{ minHeight: 420 }}>
              <Row>
                <Col span={14} style={{ fontWeight: 600 }}>Баллы по филиалам</Col>
                <Col span={10}><Select onChange={() => setStatePrize([...statePrize].reverse())} style={{ width: '100%' }} defaultValue={'desc'} options={[{ value: 'desc', label: 'По убыванию' }, { value: 'asc', label: 'По возрастанию' }]} /></Col>
              </Row>
              {statePrize.slice(0, 5).map((x: any) => <DataWithBar name={x.state} val={x?.prize} percent={x?.prize / data?.prizeByState[0]?.prize} />)}
              <div onClick={() => showAll('statePrize')} style={{ position: 'absolute', bottom: 15, right: 30, fontSize: 14, color: '#A40207', cursor: 'pointer' }}>Посмотреть все &gt;</div>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16, marginBottom: 16 }} >
          <Col xl={12} sm={24} xs={24}>
            <div className='card'>
              <Row>
                <Col span={24} >
                  <div style={{ fontWeight: 600 }}>ТОП самых проходимых тестов</div>
                </Col>
              </Row>
              {data.allTestsPopularity.slice(0, 5).map((x: any) =>
                <Row style={{ marginTop: 14 }}>
                  <Col style={{ width: 60 }}><img src={'/api/public/materials/img/' + x.imgSource} style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 50 }} /></Col>
                  <Col style={{ width: 'calc(100% - 100px)', padding: 3, lineHeight: 1.5 }}>
                    <div style={{ fontWeight: 600 }}>{x.name}</div>
                    <div className='header'>Прошли: +{x.popularity} {declinationOfNumerals(x.popularity, ['пользователь', 'пользователя', 'пользователей'])}</div>
                  </Col>
                </Row>
              )}
              <div onClick={() => showAll('test')} style={{ position: 'absolute', bottom: 15, right: 30, fontSize: 14, color: '#A40207', cursor: 'pointer' }}>Посмотреть все &gt;</div>
            </div>
          </Col>
          <Col xl={12} sm={24} xs={24}>
            <div className='card'>
              <Row>
                <Col span={24} >
                  <div style={{ fontWeight: 600 }}>ТОП тестов с наименьшим прохождением</div>
                </Col>
              </Row>
              {data.allTestsPopularity.reverse().slice(0, 5).map((x: any) =>
                <Row style={{ marginTop: 14 }}>
                  <Col style={{ width: 60 }}><img src={'/api/public/materials/img/' + x.imgSource} style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 50 }} /></Col>
                  <Col style={{ width: 'calc(100% - 100px)', padding: 3, lineHeight: 1.5 }}>
                    <div style={{ fontWeight: 600 }}>{x.name}</div>
                    <div className='header'>Прошли: +{x.popularity} {declinationOfNumerals(x.popularity, ['пользователь', 'пользователя', 'пользователей'])}</div>
                  </Col>
                </Row>
              )}
              <div onClick={() => showAll('test')} style={{ position: 'absolute', bottom: 15, right: 30, fontSize: 14, color: '#A40207', cursor: 'pointer' }}>Посмотреть все &gt;</div>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default Dashboard;
